

/* --- begin var customization ---- */
$input-btn-font-size: 0.875rem;
$input-btn-padding-x: 2rem;
$input-btn-padding-y: 1rem;


/* --- end customization ------ */

@import "~bootstrap/scss/bootstrap";

/* --- begin extended bootstrap classes ---- */




/* --- custom global styles --- */

input[type="button"] {
  font-size: 12px;
}